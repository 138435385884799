
import { Vue } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import {
  DataResponse,
  FieldsModel,
  LeadsRequestModel,
  ListItem,
  PurchasedFieldsModel,
  PurchasedLeadsModel,
  PurchaseLeadsRequestModel,
  RepurchaseLeadsRequestModel,
  SitesModel,
  SitesRequestModel,
} from "@/model";

import { ILeadsService, StripeService,ISitesService } from "@/service";
import { Settings } from "@/config";

export default class StripeSubscription extends Vue {
  @Inject("leadsService") service: ILeadsService;
  @Inject("sitesService") sitesService: ISitesService;


  public store = useStore();

  public fields: Array<FieldsModel> = [];
  public stepper: number = 1;
  public response: number = 0;
  public selectedFields: Array<any> = [];
  public sites: DataResponse<SitesModel> = new DataResponse();
  public siteId: number;

  public insufficentCredits: boolean = false;
  public fieldsError: boolean = false;
  public selectAll: boolean = false;

  public loading: boolean = false;

  mounted() {
    if (this.subscriptionMode) {
      let service = new StripeService();
      service
          .getSubscriptions(this.store.getters.getStripeId)
          .then((response) => {
            let subscribedFields = ([] as Array<ListItem>).concat(
                ...response.map((s) => s.fields)
            );

            if (subscribedFields.length) this.getAllFields(subscribedFields);
          });
    } else this.getAllFields([]);

    this.getAllSites();
  }


  public getAllSites()
  {
    const request = new SitesRequestModel();
    request._loading = true;
    this.sitesService.getSites(request).then((response) => {
      request._loading = false;
      this.sites = response;
      console.log(response);

      //this.siteIds = [this.store.getters.getSiteId];
    });
    console.log(this.sites);
  }
  public getAllFields(subscribedFields: Array<ListItem>) {
    this.loading = true;

    this.service.getAllFields().then((response) => {
      this.loading = false;
      this.fields = response
          .filter((r) => !this.subscriptionMode || subscribedFields.some((f) => f.value == r.leadFieldName))
          .map((e) => ({
            displayName: e.displayName,
            leadFieldName: e.leadFieldName,
            credit:
                e.leadFieldName == "city"
                    ? 0
                    : e.credit,
            value:
                e.leadFieldName == "email" ||
                e.leadFieldName == "city" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (false &&
                    false)
                    ? true
                    : false,
            disabled:
                e.leadFieldName == "email" ||
                this.subscriptionMode ||
                e.credit == 0 ||
                (false),
          }));
    });
  }

  public proceed2() {
    if(this.stepper == 1)
    {
      this.stepper = 2;
    }
    else
    {
      if(this.stepper == 2)
      {
        this.selectedFields = [];
        let firstName = this.fields.find((f) => f.leadFieldName == "firstName");
        if (firstName) {
          let middleName = this.fields.find((f) => f.leadFieldName == "middleName");
          let lastName = this.fields.find((f) => f.leadFieldName == "lastName");

          if (middleName) middleName.value = firstName.value;
          if (lastName) lastName.value = firstName.value;
        }

        this.fields.forEach((item) => {
          if (item.value)
            this.selectedFields.push({
              fieldName: item.leadFieldName,
              displayName: item.displayName,
              credits: item.credit ? item.credit : 0,
            });
        });

        if (this.selectedFields.length > 0) {
          this.loading = true;

          let request: any;
/**
          if (this.purchaseType == "repurchaseLead") {
             console.log(this.order);
             request = new RepurchaseLeadsRequestModel();
             request.siteId = +this.siteId;
             request.purchaseFields = this.selectedFields;
             request.orderId = this.order.orderId;
             request.purchaseType = "REPURCHASE";

             this.service.getCredits(request).then((response) => {
              this.loading = false;
              this.stepper = 2;
              this.response = response;
            });
          } else {
            this.loading = false;
            this.stepper = 3;

            //  let leadCount = this.selectedLeads.length;
            let leadCount = 1;
            //  if (this.leadRequest && this.totalLeads) leadCount = this.totalLeads;

            this.response =
                leadCount *
                this.selectedFields.map((s) => s.credits).reduce((a, b) => a + b);
          }
 **/
        } else this.fieldsError = true;
        //     this.stepper = 3;
      }
    }
  }

  public purchaseLeads2() {
    console.log("purchaseLeads2");
    /**

    this.loading = true;
    if (this.credits < this.response) this.insufficentCredits = true;
    let request: any;


    let control: any = this.$refs.upload;

    console.log("control.files.length ="+control.files.length);
    console.log("this.selectedFields ="+this.selectedFields);
    if (control.files.length) {
      this.siteService
          .uploadSiteTraffic2(this.adminSiteId, control.files[0],this.selectedFields.map((s) => ({"fieldName": s.fieldName})))
          .then((response: any) => {
            console.log(response.toString());
            this.store.dispatch("showAlert", {
              message: response,
              snackBarClass: "fa-check",
              type: "success",
            });

            this.close();
          }).catch((error: any) => {
        console.log(error);
        this.store.dispatch("showAlert", {
          message: "Error in processing File",
          snackBarClass: "fa-times",
          type: "error",
        });
      });
    }
    else
    {
      this.store.dispatch("showAlert", {
        message: "No file uploaded",
        snackBarClass: "fa-times",
        type: "error",
      });
    }

    this.$emit("purchase");
    this.loading = false;
 **/
  }

  public proceed() {
    /**
    this.selectedFields = [];
    let firstName = this.fields.find((f) => f.leadFieldName == "firstName");
    if (firstName) {
      let middleName = this.fields.find((f) => f.leadFieldName == "middleName");
      let lastName = this.fields.find((f) => f.leadFieldName == "lastName");

      if (middleName) middleName.value = firstName.value;
      if (lastName) lastName.value = firstName.value;
    }

    this.fields.forEach((item) => {
      if (item.value)
        this.selectedFields.push({
          fieldName: item.leadFieldName,
          displayName: item.displayName,
          credits: item.credit ? item.credit : 0,
        });
    });

    if (this.selectedFields.length > 0) {
      this.loading = true;

      let request: any;

      if (this.purchaseType == "repurchaseLead") {
        console.log(this.order);
        request = new RepurchaseLeadsRequestModel();
        request.siteId = +this.siteId;
        request.purchaseFields = this.selectedFields;
        request.orderId = 1;
        request.purchaseType = "REPURCHASE";

        this.service.getCredits(request).then((response) => {
          this.loading = false;
          this.stepper = 2;
          this.response = response;
        });
      } else {
        this.loading = false;
        this.stepper = 2;

        let leadCount = this.selectedLeads.length;
        if (this.leadRequest && this.totalLeads) leadCount = this.totalLeads;

        this.response =
            leadCount *
            this.selectedFields.map((s) => s.credits).reduce((a, b) => a + b);
      }
    } else this.fieldsError = true;
     **/
  }

  public checkCredits() {
    if (this.credits >= this.response) this.purchaseLeads2();
    else this.insufficentCredits = true;
  }

  public purchaseLeads() {/**
    this.loading = true;
    if (this.credits < this.response) this.insufficentCredits = true;
    let request: any;

    if (this.purchaseType == "repurchaseLead") {
      request = new RepurchaseLeadsRequestModel();
      request.siteId = +this.siteId;
      request.purchaseFields = this.selectedFields;
      request.orderId = this.order.orderId;
      request.purchaseType = "REPURCHASE";
    } else {
      request = new PurchaseLeadsRequestModel();
      request.siteId = +this.siteId;
      request.leads = this.leadRequest ? null : this.selectedLeads;
      request.leadRequest = this.leadRequest;
      request.purchaseFields = this.selectedFields;
      request.purchaseType = "PURCHASE";
    }

    this.service
        .purchaseLeads(this.purchaseType, request)
        .then((response) => {
          this.loading = false;
          this.store.dispatch("showAlert", {
            message: "Leads purchased successfully",
            snackBarClass: "fa-check",
            type: "success",
          });
          this.$emit("purchase");
        })
        .catch((error) => {
          this.loading = false;
        });
 **/
  }

  public backToFields() {
    if(this.stepper == 2)
    {
      this.stepper = 1;
    }
    else
    {
      if(this.stepper == 3)
      {
        this.stepper = 2;
      }
    }
  }

  selectAllFields() {
    this.selectAll = !this.selectAll;

    this.fields.forEach((f) => {
      if (!f.disabled) f.value = this.selectAll;
    });
  }

  public buyCredits() {
    let route = this.$router.resolve({ path: "/billing" });
    window.open(route.href, "_blank");
  }

  public close() {
    this.$emit("closeCreateSubscriptionModel", false);
  }

  get credits() {
    return this.store.getters.getCredits;
  }

  get subscriptionMode() {
    return Settings.SubscriptionMode;
  }
}
