
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import { Settings } from "@/config";
import { ICreditHistoryService } from "@/service";
import { AllCreditValueModel, StripeCheckoutModel } from "@/model";

import Selectbox from "@/components/controls/Selectbox.vue";

import { StripeCheckout } from "@vue-stripe/vue-stripe";

@Options({
  components: {
    Selectbox,
    StripeCheckout,
  },
})
export default class PurchaseCredits extends Vue {
  @Inject("CreditHistoryService") service: ICreditHistoryService;

  public store = useStore();
  public response: Array<AllCreditValueModel> = [];
  public credits: number = 0;
  public creditsToggle: boolean = false;
  public publishableKey: string = "";
  public lineItems: any = [];
  public payNow: boolean = false;
  public successUrl: string = "";
  public cancelUrl: string = "";
  public sessionId: string;

  public loading: boolean = false;
  public validationError: boolean = false;

  public amount: number = 0;

  created() {
    this.publishableKey = Settings.PublicKey;
    this.successUrl = window.location.origin + "/success";
    this.cancelUrl = window.location.origin + "/cancel";
    this.getAllCreditValues();
  }

  private getAllCreditValues() {
    this.loading = true;
    this.service.getAllCreditValues().then((response) => {
      this.loading = false;
      this.response = response;
      this.updatePrice(this.response[0].creditPoint);
    });
  }

  public updatePrice(credits: number) {
    this.credits = credits;
    this.creditsToggle = false;
    this.amount = this.credits * 1;
  }

  public clickOutSideCredits() {
    this.creditsToggle = false;
  }

  public purchase() {
    if (this.credits >= this.response[0].creditPoint) {
      this.lineItems = [
        {
          price: Settings.StripeCreditId,
          quantity: parseInt(this.credits.toString()),
        },
      ];

      let request = new StripeCheckoutModel();
      request.successURL = this.successUrl;
      request.cancelURL = this.cancelUrl;
      request.mode = "payment";
      request.lineItems = this.lineItems;

      this.service.createSession(request).then((sessionId) => {
        this.payNow = true;
        this.sessionId = sessionId;

        this.store.dispatch("addPurchaseCredits", {
          credits: this.credits,
          amount: this.amount,
        });
        setTimeout(() => {
          (this.$refs.checkoutRef as any).redirectToCheckout();
        }, 1000);
      });
    } else {
      this.validationError = true;
    }
  }

  public cancel() {
    this.$emit("cancel");
  }

  public onCreditChange() {
    this.amount = this.credits > 0 ? this.credits : 0;

    this.validationError = this.credits < this.response[0].creditPoint;
  }

  get currentUser() {
    return this.store.getters.getCurrentUser;
  }
}
