export class Settings {
    public static ApiUrl: string = process.env.VUE_APP_APIBASEURL;
    public static ApiPath: string = process.env.VUE_APP_APIPATH;
    
    public static AuthUserName: string = process.env.VUE_APP_AUTHUSERNAME;
    public static AuthPassword: string = process.env.VUE_APP_AUTHPASSWORD;

    public static PublicKey: string = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

    public static AppName: string = process.env.VUE_APP_NAME;
    public static AppLogo: string = process.env.VUE_APP_LOGO;
    public static AppSupportLogo: string = process.env.VUE_APP_SUPPORT_LOGO;
    public static AppFavIcon: string = process.env.VUE_APP_FAVICON;
    public static AppTitle: string = process.env.VUE_APP_TITLE;
    public static AppCaption: string = process.env.VUE_APP_CAPTION;
    public static AppAddress: string = process.env.VUE_APP_ADDRESS;
    public static LeadName: string = process.env.VUE_APP_LEADNAME;
    public static ReleaseMode: string = process.env.VUE_APP_RELEASEMODE;
    public static Announcement: string = process.env.VUE_APP_ANNOUNCEMENT;
    public static MegaDataInitCount: string = process.env.VUE_APP_MEGA_DATA_INITCOUNT;

    public static EnableOTP: boolean = process.env.VUE_APP_ENABLE_OTP === "true";
    public static ManualUpload: boolean = process.env.VUE_APP_MANUALUPLOAD === "true";
    public static EnableRegistration: boolean = process.env.VUE_APP_ENABLE_REGISTRATION === "true";

    public static StripeCreditId: string = process.env.VUE_APP_STRIPE_CREDIT_ID;

    public static SubscriptionMode: boolean = process.env.VUE_APP_STRIPE_SUBSCRIPTION_MODE === "true";

    public static AeroLeadAnalyticsIcon: boolean = process.env.VUE_APP_ANALYTICS_ICON;
}

