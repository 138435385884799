<template>
    <div class="container-fluid fill-height">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-sm-12 col-md-6">
                <div>
                    <img src="../../assets/page-not-found.png" alt="Page not found" width="500">
                    <p class="text--align-center text--size-4 color--orange">Page Not Found!!</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class PageNotFound extends Vue {
    
}
</script>