export enum AuthStatus {
    Loading = 1,
    Success = 2,
    BadCredentials = 3,
    Failed = 4
}

export enum AlertType {
    Success = 1,
    Info = 2,
    Warning = 3,
    Error = 4
}

export enum DateRangeOption {
    ThisWeek = 1,
    NextWeek = 2,
    ThisMonth = 3,
    NextMonth = 4,
    LastWeek = 5,
    LastMonth = 6
}
export enum LeadDateStatus {
    'Last 24 hours' = "LASTDAY",
    'This week' = "THISWEEK",
    'Last week' = "LASTWEEK", 
    'This month' = "THISMONTH",
}
