
import { Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import axios from "axios";
import { BaseComponent } from "@/components/BaseComponent";

import { useStore } from "vuex";

import { IUserService } from "@/service";
import {
  UsersRequestModel,
  UsersResponseModel,
  CreateUserRequestModel,
} from "@/model";

import CreateUser from "./components/CreateUser.vue";
import AssignUser from "./components/AssignUser.vue";
import AssignCredit from "./components/AssignCredit.vue";
import UpdateSubscription from "./components/UpdateSubscription.vue";
import ChangePassword from "./components/ChangePassword.vue";
import InviteUser from "./components/InviteUser.vue";

@Options({
  components: {
    CreateUser,
    AssignUser,
    AssignCredit,
    UpdateSubscription,
    ChangePassword,
    InviteUser,
  },
})
export default class Users extends BaseComponent {
  @Inject("userService") service: IUserService;

  public store = useStore();
  public subscription: any = null;

  public siteId: number = 0;
  public firstName: string = "";

  public accessType: string = "Add User";

  public toggleCreateModel: boolean = false;
  public toggleAssignUserModel: boolean = false;
  public toggleAssignCreditModel: boolean = false;
  public toggleUpdateSubscriptionModel: boolean = false;
  public toggleInviteUserModel: boolean = false;
  public toggleChangePasswordModel: boolean = false;
  public loading: boolean = false;
  public selectedUser: UsersResponseModel;

  public request = new UsersResponseModel();
  public response: Array<UsersResponseModel> = [];

  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public currentUserId: number;

  created() {
    this.getUsers();

    let user: CreateUserRequestModel = this.store.getters.getCurrentUser;

    this.isAdmin = user.role.indexOf("ADMIN") > -1;
    this.isSuperAdmin = user.role.indexOf("SUPERADMIN") > -1;
    this.currentUserId = user.id;
  }

  mounted() {
    this.subscription = this.store.subscribe((mutations, type) => {
      if (mutations.type == "onChangeSiteId") this.getUsers();
    });
  }

  public getUsers() {
    if (this.store.getters.getSiteId) {
      this.loading = true;
      const request = new UsersRequestModel();
      request.siteId = this.store.getters.getSiteId;
      this.service.getUser(request).then((response) => {
        this.loading = false;
        if (!this.isSuperAdmin)
          response = response.filter((r) => r.role != "SUPERADMIN");
        console.log("user response "+response);

        this.response = response.sort((n1, n2) => {
          if (n1.email > n2.email) {
            return 1;
          }

          if (n1.email < n2.email) {
            return -1;
          }

          return 0;
        });
      });
    } else this.$router.push("/sites");
  }

  public changePassword(request: UsersResponseModel) {
    this.selectedUser = request;

    this.toggleChangePasswordModel = true;
  }

  public assignCredit(request: UsersResponseModel) {
    this.selectedUser = request;

    this.toggleAssignCreditModel = true;
  }

  public updateSubscription(request: UsersResponseModel) {
    this.selectedUser = request;

    this.toggleUpdateSubscriptionModel = true;
  }

  public onCloseAssignCredit() {
    this.toggleAssignCreditModel = false;
  }

  public onCloseUpdateSubscription() {
    this.toggleUpdateSubscriptionModel = false;
  }

  public assignedCredit() {
    window.location.reload();
  }

  public updatedSubscription() {
    window.location.reload();
  }

  public createUser(accessType: string, request?: UsersResponseModel) {
    if (request) this.request = request;
    this.accessType = accessType;
    this.toggleCreateModel = true;
  }

  public onCloseCreateUserModel(trigger: boolean) {
    this.toggleCreateModel = false;
    this.accessType = "";
    if (trigger) this.getUsers();
  }

  public onCloseAssignModel() {
    this.toggleAssignUserModel = false;
  }

  public assignedEmail() {
    this.toggleAssignUserModel = false;
    this.store.dispatch("showAlert", {
      message: "User Assignment Request was successful. You will Receive an Email Once approved",
      snackBarClass: "fa-check",
      type: "suceess",
    });
    this.getUsers();
  }

  public onCloseInviteModel() {
    this.toggleInviteUserModel = false;
  }

  public invitedEmail() {
    this.toggleInviteUserModel = false;
    this.store.dispatch("showAlert", {
      message: "User Invite Request was successful. You will Receive an Email Once approved",
      snackBarClass: "fa-check",
      type: "suceess",
    });
    this.getUsers();
  }

  private deleteUser(item: UsersResponseModel) {
    this.confirm(
        `Are you sure you want the user "${item.firstName} ${item.lastName}, ${item.email}"?`
    ).then((confirm: boolean) => {
      if (confirm) {
        axios.defaults.headers.common["Authorization"] = "";
        this.service.deleteUser(item.id).then((response) => {
          this.store.dispatch("showAlert", {
            message: "User deleted successfully",
            snackBarClass: "fa-check",
            type: "suceess",
          });
          axios.defaults.headers.common["Authorization"] =
              "Bearer " + this.store.getters.accessToken;
          this.getUsers();
        });
      }
    });
  }

  approveUser(item : UsersResponseModel) {
    item.enabled = true;
    item.type = "ADDUSER";
    item.siteId = this.store.getters.getSiteId;
    let restrict: any = item;
    this.loading = true;
    axios.defaults.headers.common["Authorization"] = "";
    this.service
        .updateUser(restrict)
        .then((response) => {
          this.loading = false;
          this.store.dispatch("showAlert", {
            message: item.locked
                ? "User approved successfully"
                : "User approved successfully",
            snackBarClass: "fa-check",
            type: "suceess",
          });
          axios.defaults.headers.common["Authorization"] =
              "Bearer " + this.store.getters.accessToken;
          this.getUsers();
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: error.response.data,
              snackBarClass: "fa-times",
              type: "error",
            });
          this.loading = false;
        });
  }

  public restrictUser(item: UsersResponseModel) {
    item.locked = !item.locked;
    item.type = "ADDUSER";
    item.siteId = this.store.getters.getSiteId;
    let restrict: any = item;
    this.loading = true;
    axios.defaults.headers.common["Authorization"] = "";
    this.service
        .updateUser(restrict)
        .then((response) => {
          this.loading = false;
          this.store.dispatch("showAlert", {
            message: item.locked
                ? "User locked successfully"
                : "User unlocked successfully",
            snackBarClass: "fa-check",
            type: "suceess",
          });
          axios.defaults.headers.common["Authorization"] =
              "Bearer " + this.store.getters.accessToken;
          this.getUsers();
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: error.response.data,
              snackBarClass: "fa-times",
              type: "error",
            });
          this.loading = false;
        });
  }
}
