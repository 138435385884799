
import { Vue, Options, setup } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { ISitesService } from "@/service";
import { CreateSiteRequestModel } from "@/model";

@Options({
  validations: {
    request: {
      siteName: { required },
      siteUrl: {
        required,
        urlValidation: function (value: any) {
            //const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            const regexp = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
            const validation=  regexp.test(value);
            return validation;
        },
      },
    },
  },
})
export default class CreatSite extends Vue {
  @Inject("sitesService") service: ISitesService;

  public v$ = setup(() => this.validate());
  public store = useStore();
  public request = new CreateSiteRequestModel();

  public loading: boolean = false;

  validate() {
    return useVuelidate();
  }

  public createSite() {
    this.v$.$touch();

    if (!this.v$.$invalid) {
      this.loading = true;

      this.service
        .createSite(this.request)
        .then((response) => {
          this.loading = false;
          this.store.dispatch("showAlert", {
            message: "Site created sucessfully",
            snackBarClass: "fa-check",
            type: "success",
          });
          this.$emit("closeSiteModel", true);
        })
        .catch((error) => {
          this.loading = true;
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: error.response.data.message,
              snackBarClass: "fa-times",
              type: "error",
            });
          this.$emit("closeSiteModel", true);
        })
        .catch((error) => {
          this.loading = true;
          if (error.response.status == 400)
            this.store.dispatch("showSnack", {
              message: error.response.data.message,
              snackBarClass: "snack--bar__error",
            });
          this.loading = false;
        });
    }
  }

  public close() {
    this.$emit("closeSiteModel", false);
  }
  
}
