import { IBaseService, BaseService } from './base.service';
import {
    DataResponse,
    UsersRequestModel,
    UsersResponseModel,
    CreateUserRequestModel,
    CreateUserModel,
    CreditsRequestModel,
    AssignUserRequestModel,
    AssignUserToMultiSiteRequestModel, CreateMultiSiteUserRequestModel
} from '@/model';

export interface IUserService extends IBaseService<UsersRequestModel, UsersResponseModel> { 
    getUserById(id: string): Promise<UsersResponseModel>;
    getUser(request: UsersRequestModel): Promise<Array<UsersResponseModel>>;
    addUser(request: CreateUserRequestModel): Promise<CreateUserModel>;
    addMultiSiteUser(request: CreateMultiSiteUserRequestModel): Promise<CreateUserModel>;
    updateUser(request: CreateUserRequestModel): Promise<CreateUserModel>;
    registerUser(request: CreateUserRequestModel): Promise<CreateUserModel>;
    deleteUser(id: number): Promise<any>;
    getCredits(): Promise<any>;
    getCurrentUser(): Promise<CreateUserRequestModel>;
    assignUser(request: AssignUserRequestModel): Promise<any>;
    changePasswordByAdmin(email: string, password: string): Promise<any>;
    assignUserToMultiSite(request: AssignUserToMultiSiteRequestModel): Promise<any>;
}

export class UserService extends BaseService<UsersRequestModel, UsersResponseModel> implements IUserService { 

    constructor() {
        super('user');
    }

    getUserById(id: string): Promise<UsersResponseModel> {
        return this.httpGet(`public/user/UUID/${id}`, null).then(response => {
            return response.data;
        })
    }

    getUser(request: UsersRequestModel): Promise<Array<UsersResponseModel>> {
        return this.httpGet('user', request).then(response => {
            return response.data;
        })
    }

    addUser(request: CreateUserRequestModel): Promise<CreateUserModel> {
        return this.httpPost('public/user', request).then(response => {
            return response.data;
        });
    }

    addMultiSiteUser(request: CreateMultiSiteUserRequestModel): Promise<CreateUserModel> {
        return this.httpPost('public/multisite/user', request).then(response => {
            return response.data;
        });
    }

    updateUser(request: CreateUserRequestModel): Promise<CreateUserModel> {
        return this.httpPost('public/updateUser', request).then(response => {
            return response.data;
        })
    }

    registerUser(request: CreateUserRequestModel): Promise<CreateUserModel> {
        return this.httpPost('public/registerUser', request).then(response => {
            return response.data;
        })
    }

    deleteUser(id: number): Promise<any> {
        return this.httpPut(`admin/deleteUser/${id}`, null).then(response => {
            return response.data;
        })
    }

    getCredits(): Promise<any> {
        return this.httpGet('admin/user/getCredits', null).then(response => {
            return response.data;
        })       
    }

    getCurrentUser(): Promise<CreateUserRequestModel> {
        return this.httpGet('admin/user/getCurrentUser', null).then(response => {
            return response.data;
        })
    }

    assignUser(request: AssignUserRequestModel): Promise<any> {
        return this.httpPost('admin/siteUser', request).then(response => {
            return response.data;
        })
    }

    changePasswordByAdmin(email: string, password: string) {
        return this.httpPost('admin/user/changePasswordByAdmin', { email: email, newPassword: password}).then(response => {
            return response.data;
        })
    }

    assignUserToMultiSite(request: AssignUserToMultiSiteRequestModel): Promise<any> {
        return this.httpPost('admin/siteUser', request).then(response => {
            return response.data;
        })
    }
}