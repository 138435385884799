
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import {ICreditHistoryService, ILeadsService} from "@/service";
import { useStore } from "vuex";
import { Settings } from "@/config";

import {
  CreditHistoryModel,
  CreditHistoryRequestModel,
  DataResponse,
  LeadsRequestModel,
  PurchasedLeadsModel
} from "@/model";
import PurchaseCredits from "./components/PurchaseCredits.vue";
import OpenRefund from "./components/OpenRefund.vue"
import PrintLeadsReceipt from "./components/ReceiptPrint.vue";

@Options({
  components: {
    PurchaseCredits,
    OpenRefund,
    PrintLeadsReceipt,
  },
})
export default class Billing extends Vue {
  @Inject("CreditHistoryService") service: ICreditHistoryService;
  @Inject("leadsService") leadService: ILeadsService;

  public togglePurchaseCreditModel: boolean = false;
  public toggleOpenRefund: boolean = false;
  public toggleReceiptPrintModel: boolean = false;
  public response: Array<CreditHistoryModel> = [];
  public leadsResponse: DataResponse<PurchasedLeadsModel> = new DataResponse();;

  public store = useStore();

  public loading: boolean = false;

  mounted() {
    this.getAllCreditHistory();
    this.getPurchangedLeads();
    this.store.dispatch("updateCredits");
  }

  public getPurchangedLeads() {
    this.loading = true;
    const request = new LeadsRequestModel();
    request._loading = true;
    request.ascending = false;
    request.sortColumn = "purchaseDate";
    request.siteId = this.store.getters.getSiteId;
    this.leadService.getPurchangedLeads(request).then((response) => {
      this.loading = false;
      this.leadsResponse = response;
    });
  }

  public getAllCreditHistory() {
    this.loading = true;
    const request = new CreditHistoryRequestModel();
    this.service.getAllCreditHistory(request).then((response) => {
      this.loading = false;
      this.response = response;
    });
  }

  public onCancelPurchase() {
    this.togglePurchaseCreditModel = false;
  }
  public onCancelRefund() {
    this.toggleOpenRefund = false;
  }

  get leadReceiptLink() {
    return `${Settings.ApiUrl}/${Settings.ApiPath}/leadsreceipt/pdftemplate/`;
  }

  public onCloseReceiptPrintModel(trigger: boolean) {
    this.toggleReceiptPrintModel = false;
  }
  public openReceipt(ev: any) {
    console.log(ev); // MouseEvent { ... }
    this.toggleReceiptPrintModel = true;
  }

  public openLeadsReceiptPrint(orderId: number){
    window.open(this.leadReceiptLink + orderId, 'popup','toolbar=no,scrollbars=yes,location=no,statusbar=no,menubar=no,width=1000,height=1000');
    return false;
  }
}
