import { IBaseService, BaseService } from './base.service';
import { MegaDataRequestModel, MegaDataModel } from '@/model';

export interface IMegaDataService extends IBaseService<MegaDataRequestModel, MegaDataModel> {
    sendMegaDataRequest(request: MegaDataRequestModel): Promise<MegaDataModel>;
}

export class MegaDataService extends BaseService<MegaDataRequestModel, MegaDataModel> implements IMegaDataService {

    constructor() {
        super('admin');
    }

    sendMegaDataRequest(request: MegaDataRequestModel): Promise<MegaDataModel> {
        return this.post(request, 'megadata').then((response) => {
            return response;
        })
    }
}
