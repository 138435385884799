import { IBaseService, BaseService } from './base.service';
import {
    DataResponse,
    LeadsRequestModel,
    LeadsModel,
    PurchasedLeadsModel,
    PurchaseLeadsRequestModel,
    FieldsResponseModel,
    Dictionary, LeadsCountRequestModel
} from '@/model';

export interface ILeadsService extends IBaseService<LeadsRequestModel, LeadsModel> {
    getAvailableLeads(request: LeadsRequestModel): Promise<DataResponse<LeadsModel>>
    getPurchangedLeads(request: LeadsRequestModel): Promise<DataResponse<PurchasedLeadsModel>>;
    getAllLeadsCount(): Promise<number>;
    getAllFields(): Promise<Array<FieldsResponseModel>>;
    getCredits(request: PurchaseLeadsRequestModel): Promise<any>;
    purchaseLeads(path: string, request: PurchaseLeadsRequestModel): Promise<any>;
    exportFile(fileName: string, orderId: number): Promise<any>;

    leadCountByState(siteId: number): Promise<Dictionary<number>>;
    leadCountByZipCode(siteId: number, state: string): Promise<Dictionary<number>>;
    exportRawIPFile(fileName: string, siteId: number, leadIds: string): Promise<any>;
}

export class LeadsService extends BaseService<LeadsRequestModel, LeadsModel> implements ILeadsService {

    constructor() {
        super('admin/lead');
    }

    public leadCountByState(siteId: number): Promise<Dictionary<number>> {
        return this.httpGet('admin/lead/leadCountByState', { siteId: siteId }).then((response) => {
            return response.data;
        })
    }

    public leadCountByZipCode(siteId: number, state: string): Promise<Dictionary<number>> {
        return this.httpGet('admin/lead/leadCountByZipCode', { siteId: siteId, state: state }).then((response) => {
            return response.data;
        })
    }

    public getAvailableLeads(request: LeadsRequestModel): Promise<DataResponse<LeadsModel>> {
        return this.searchPost(request, 'availableLeads').then((response) => {
            return response;
        })
    }

    public getPurchangedLeads(request: LeadsRequestModel): Promise<DataResponse<PurchasedLeadsModel>> {
        return this.httpPost('admin/lead/getPurchasedLeads', request).then((response) => {
            return response.data;
        })
    }


    public getAllLeadsCount(): Promise<number> {
        return this.httpGet('admin/lead/allLeadCount',{}).then((response) => {
            return response.data;
        })
    }



    public getAllFields(): Promise<Array<FieldsResponseModel>> {
        return this.httpGet('admin/getAllFields', null).then((response) => {
            return response.data;
        })
    }

    public getCredits(request: PurchaseLeadsRequestModel): Promise<any> {
        return this.httpPost('admin/getCredits', request).then((response) => {
            return response.data;
        })
    }

    public purchaseLeads(path: string, request: PurchaseLeadsRequestModel): Promise<any> {
        return this.httpPost(`${'admin/' + path}`, request).then((response) => {
            return response.data;
        });
    }

    public exportFile(fileName: string, orderId: number): Promise<any> {
        let request = { 'orderId': orderId };
        return this.httpGet('admin/lead/export', request, "blob").then((response) => {
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            fileLink.style.display = "none";
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
        })
    }

    public exportRawIPFile(fileName: string, siteId: number, leadIds: string): Promise<any> {
        let request = { 'leadIds': leadIds, 'siteId': siteId };
        return this.httpGet('admin/lead/exportRawIps', request, "blob").then((response) => {
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            fileLink.style.display = "none";
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
        })
    }

}
