
import {Vue, Options} from "vue-class-component";
import {Inject} from "vue-property-decorator";
import {useStore} from "vuex";

import {ILeadsService, IDataRequestFormService} from "@/service";
import {
  DataResponse,
  LeadsRequestModel,
  LeadsModel,
  StatesResponseModel,
  LeadDateStatus,
  Dictionary,
  ListItem,
} from "@/model";

import PurchaseLeads from "../components/PurchaseLeads.vue";
import Pagination from "@/components/controls/Pagination.vue";
import {Settings} from "@/config";

@Options({
  components: {
    PurchaseLeads,
    Pagination,
  },
})
export default class AvaliableLeads extends Vue {
  @Inject("leadsService") service: ILeadsService;
  @Inject("dataRequestFormService")
  dataRequestFormService: IDataRequestFormService;

  public store = useStore();

  public togglePurchaseModel: boolean = false;
  public payNow: boolean = false;
  public isAllSelected: boolean = false;

  public price: number = 0;
  public selectedLeads: Array<number> = [];
  public subscription: any = null;

  public request = new LeadsRequestModel();
  public response: DataResponse<LeadsModel> = new DataResponse();

  public showFilters: boolean = false;

  public stateToggle: boolean = false;
  public states: Array<ListItem> = [];
  public zipCodes: Array<ListItem> = [];
  public googleSearchKeywords: string;

  public isSelectAll: boolean = false;

  public leadDateToggle: boolean = false;
  public leadDate: Array<string> = [
    "Last 24 hours",
    "This week",
    "Last week",
    "This month",
  ];

  mounted() {
    this.subscription = this.store.subscribe((mutations, type) => {
      if (mutations.type == "onChangeSiteId") {
        this.getLeads();
        this.selectedLeads = [];
        this.getStates();
      }
    });

    this.getLeads();
    this.selectedLeads = [];
    this.getStates();
  }

  unmounted() {
    if (this.subscription) this.subscription();
  }

  public getStates() {
    this.service.leadCountByState(this.siteId).then((response) => {
      let items = new Array<ListItem>();
      for (let key in response) {
        items.push(new ListItem(key, response[key].toString()));
      }

      this.states = items.sort((n1, n2) => {
        if (
            parseInt(n1.value ? n1.value : "0") >
            parseInt(n2.value ? n2.value : "0")
        ) {
          return -1;
        }

        if (
            parseInt(n1.value ? n1.value : "0") <
            parseInt(n2.value ? n2.value : "0")
        ) {
          return 1;
        }

        return 0;
      });
    });
  }

  public getZipCodes(state: ListItem) {
    if (state.selected) {
      this.service
          .leadCountByZipCode(this.siteId, state.text)
          .then((response) => {
            let items = new Array<ListItem>();
            for (let key in response) {
              items.push(new ListItem(key, response[key].toString(), state.text));
            }

            this.zipCodes.push(
                ...items.sort((n1, n2) => {
                  if (
                      parseInt(n1.value ? n1.value : "0") >
                      parseInt(n2.value ? n2.value : "0")
                  ) {
                    return -1;
                  }

                  if (
                      parseInt(n1.value ? n1.value : "0") <
                      parseInt(n2.value ? n2.value : "0")
                  ) {
                    return 1;
                  }

                  return 0;
                })
            );
          });
    } else {
      this.zipCodes = this.zipCodes.filter((z) => z.data != state.text);
    }
  }


  getLeads(pageSize = false) {
    this.request.siteId = this.siteId;
    this.request.sortColumn = "creationDateTime";
    this.request.ascending = false;
    if (this.request.leadDate)
      this.request.daysFilter =
          LeadDateStatus[
              this.request.leadDate.toString() as keyof typeof LeadDateStatus
              ];
    else this.request.daysFilter = undefined;

    this.request.state = this.states
        .filter((s) => s.selected)
        .map((s) => s.text);

    this.request.zipcode = this.zipCodes
        .filter((s) => s.selected)
        .map((s) => s.text);

    this.request._loading = true;
    if (pageSize) {
      this.request.pageSize = -1;
    } else {
      this.request.pageSize = 50;
    }
    this.service.getAvailableLeads(this.request).then((response) => {
      this.request._loading = false;
      this.response = response;

      if (this.isSelectAll) {
        let finalData: Array<number> = [];
        response.data.map(function (rec) {
          finalData.push(rec.id);
        });
        this.selectedLeads = finalData;
      }
    });
  }

  public controlWithPagination(action: string) {
    this.request.page =
        action == "prev" ? this.request.page - 1 : this.request.page + 1;
    this.getLeads();
  }

  public applyFilters() {
    this.showFilters = false;
    this.request.page = 1;
    this.getLeads();
    this.selectedLeads = [];
    this.isSelectAll = false;
  }

  public selectAllLeads() {
    this.isSelectAll = !this.isAllSelected;
    this.getLeads(!this.isAllSelected);
    this.isAllSelected = !this.isAllSelected;
  }

  public clearSelectedLeads() {
    this.isSelectAll = false;
    this.isAllSelected = false;
    this.getLeads();
    this.selectedLeads = [];
  }

  public exportRawIP() {
    this.request._loading = true;
    const fileName =
        this.store.getters.getSiteName +
        "_" +
        new Date().getUTCMilliseconds() +
        ".xlsx";
    this.service
        .exportRawIPFile(
            fileName.replace(/ /g, ''),
            this.store.getters.getSiteId,
            this.selectedLeads.join(",")
        )
        .finally(() => {
          this.request._loading = false;
          this.isSelectAll = false;
          this.isAllSelected = false;
        });
    this.selectedLeads = [];
  }

  public clearFilters() {
    this.states.forEach((s) => (s.selected = false));
    this.zipCodes.forEach((s) => (s.selected = false));
    this.request.leadDate = "";
  }

  closeModel(value: boolean) {
    this.togglePurchaseModel = value;
  }

  updatePurchaseModel() {
    this.togglePurchaseModel = false;
    this.store.dispatch("updateCredits");

    this.selectedLeads = [];

    this.$emit("purchaseCompleted");
  }

  public clickOutSideFilter() {
    if (!this.stateToggle) this.showFilters = false;
  }

  private getSearchFilterValue(value: any) {
    return value != "Select" ? value : null;
  }

  onSelectAll(value: boolean) {
    this.isSelectAll = !this.isSelectAll;

    this.selectedLeads = [];
  }

  onCheckAll(value: boolean) {
    this.response.data.forEach((d) => {
      let s = this.selectedLeads.find((l) => {
        return l == d.id;
      });

      if (value) {
        this.selectedLeads.push(d.id);
      } else {
        if (s) this.selectedLeads.splice(this.selectedLeads.indexOf(s), 1);
      }
    });
  }

  get selectedStates() {
    return this.request.state ? this.request.state.join(", ") : "";
  }

  get selectedZipcodes() {
    return this.request.zipcode ? this.request.zipcode.join(", ") : "";
  }

  get selectedDateRange() {
    let value = "";
    switch (this.request.daysFilter) {
      case "LASTDAY":
        value = "Last 24 hours";
        break;
      case "THISWEEK":
        value = "This week";
        break;
      case "LASTWEEK":
        value = "Last week";
        break;
      case "THISMONTH":
        value = "This month";
        break;
    }

    return value;
  }

  get leads() {
    return this.selectedLeads.map((s) => s);
  }

  get siteId() {
    return this.store.getters.getSiteId;
  }

  get isCheckAll() {
    let value: boolean =
        this.selectedLeads.length > 0 &&
        this.response.data.length > 0 &&
        this.selectedLeads.length >= this.response.data.length;

    if (value) {
      this.response.data.forEach((d) => {
        if (!this.selectedLeads.some((s) => s == d.id)) value = false;
      });
    }

    return value;
  }

  get leadName() {
    return Settings.LeadName;
  }
}
