import { ListItem } from "./base.model";

export class SubscriptionModel {
    id: string;
    isBasePlan: boolean;
    
    startDate: Date;
    amount: number;
    fields: Array<ListItem>;
}


export class SubscriptionProductModel {
    id: number;
    name: string;
    field: string;
    amount: number;
    price: string;
    isBasePlan: boolean;

    selected: boolean = false;
    disabled: boolean = false;
}


export class InvoiceModel {
    id: string;
    amount: number;
    date: Date;
}