
import {Vue, Options} from "vue-class-component";
import {useStore} from "vuex";
import {Inject} from "vue-property-decorator";

import {
  ISitesService,
  IUserService,
  INotificationsService,
  StripeService, ILeadsService,
  IAppService,
} from "@/service";
import {
  DataResponse,
  SitesRequestModel,
  SitesModel,
  NotificationsModel,
  CreateUserRequestModel, LeadsCountRequestModel, ListItem,
  AppParamsModel
} from "@/model";

import AppAlert from "@/components/layout/AppAlert.vue";
import AppConfirm from "@/components/layout/AppConfirm.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import AppParams from "./components/AppParams.vue";
import ChangePassword from "./components/ChangePassword.vue";
import CreateUser from "../Users/components/CreateUser.vue";
import Help from "./components/HelpComponent.vue";
import {Settings} from "@/config";

@Options({
  components: {
    AppAlert,
    AppConfirm,
    AppFooter,
    CreateUser,
    ChangePassword,
    AppParams,
    Help,
  },
})
export default class Home extends Vue {
  @Inject("sitesService") sitesService: ISitesService;
  @Inject("userService") service: IUserService;
  @Inject("leadsService") leadsService: ILeadsService;
  @Inject("notificationsService") notificationsService: INotificationsService;
  @Inject("appService") appService: IAppService;

  public store = useStore();

  public appResponse: DataResponse<AppParamsModel> =  new DataResponse();

  public isSuperAdmin: boolean = false;
  public userToggle = false;

  public sitesToggle: boolean = false;

  public siteResponse: DataResponse<SitesModel> = new DataResponse();

  public siteWithLeadsResponse: DataResponse<SitesModel> = new DataResponse();

  public siteWithLeads: any ;

  public allow: boolean = false;

  public siteAvailableLeads: boolean = false;
  public leadsAvailableCount: number = 0;


  public showNotifications: boolean = false;
  public allowChangePassword: boolean = false;

  public toggleEditProfileModel: boolean = false;

  public toggleAppParamsModel: boolean = false;

  public userRequest = new CreateUserRequestModel();

  public notifications: Array<NotificationsModel> = [];
  public notificationCount: number = 0;
  public credit: number = 0;
  public megaDataCount: number = 0;
  public user: CreateUserRequestModel = new CreateUserRequestModel();

  created() {
    this.user = this.store.getters.getCurrentUser;
    this.isSuperAdmin = this.user.role.indexOf("SUPERADMIN") > -1;

    if (Settings.SubscriptionMode && this.user) {
      if (!this.store.getters.getStripeId) this.setStripeId(this.user);
      else this.checkSubscription();
    }

    this.getAllSites();
    this.getAllSitesWithLeadsAvailable();
    this.getMegaDataCount();
    this.store.dispatch("updateCredits");
    this.getNotifications();
    this.getNotificationCount();
  }

  public editAppParams() {

    this.getAllAppParams();
    this.toggleAppParamsModel = true;
    console.log("Edit App Params called");
  }

  setStripeId(user: any) {
    let service = new StripeService();

    service.getCustomer(user.email).then((response) => {
      if (response) {
        this.store.dispatch("setStripeId", response.id);

        this.checkSubscription();
      } else {
        this.$router.push({
          name: "Subscriptions",
          params: {subscriptions: 0},
        });
      }
    });
  }

  public getAeroleadAnalyticsIcon() {
    if (Settings.AeroLeadAnalyticsIcon != null) {
      return Settings.AeroLeadAnalyticsIcon;
    }
    return false;
  }

  public getAllAppParams() {
   // this.request._loading = true;
    this.appService.getAllAppParams().then((response) => {
  //    this.request._loading = false;
      this.appResponse = response;
      console.log(response);
      console.log(response.data);
      console.log(response['data']);
    });
  }

  public hasSiteAvailableLeads(varSite: any) {
    for (let site in this.siteWithLeadsResponse.data) {
      let leads = JSON.parse(this.siteWithLeadsResponse.data[site].jsonInfo);
      if (leads.siteId == varSite.id) {
       // this.siteAvailableLeads = true;
       // this.leadsAvailableCount = leads.leadCount;
        return true;
      }
    }
    return false;
    /**
    if(this.siteWithLeads[siteId] != null)
    {
      this._leadsAvailableCount = this.siteWithLeads[siteId].leadCount;
      return true;
    }
    return false;
     **/
  }

  public getSiteAvailableLeads(varSite: any) {
    for (let site in this.siteWithLeadsResponse.data) {
      let leads = JSON.parse(this.siteWithLeadsResponse.data[site].jsonInfo);
      if (leads.siteId == varSite.id) {
        // this.siteAvailableLeads = true;
        // this.leadsAvailableCount = leads.leadCount;
        return leads.leadCount;
      }
    }
    return 0;
    /**
     if(this.siteWithLeads[siteId] != null)
     {
      this._leadsAvailableCount = this.siteWithLeads[siteId].leadCount;
      return true;
    }
     return false;
     **/
  }

  checkSubscription() {
    let service = new StripeService();

    service
        .getSubscriptions(this.store.getters.getStripeId)
        .then((response) => {
          if (response.filter((s) => s.isBasePlan).length == 0)
            this.$router.push({
              name: "Subscriptions",
              params: {subscriptions: 0},
            });
        });
  }

  mounted() {
    let root: any = this.$root;

    let confirm: AppConfirm = this.$refs.confirm as AppConfirm;
    root.$confirm = confirm.show;

    document.title = Settings.AppTitle;
  }

  public getMegaDataCount() {

    const request = new LeadsCountRequestModel();

    this.leadsService.getAllLeadsCount().then((response) => {
      this.megaDataCount = parseInt(Settings.MegaDataInitCount) + response;
    });
  }

  public getAllSites() {
    const request = new SitesRequestModel();
    this.sitesService.getSites(request).then((response) => {
      this.siteResponse = response;
      this.allow = true;

      if (this.siteResponse.data.length <= 0) this.$router.push("/sites");
      else {
        if (!this.siteName || !this.siteId) {
          this.store.dispatch("updateSiteId", this.siteResponse.data[0].id);
          this.store.dispatch(
              "updateSiteName",
              this.siteResponse.data[0].siteName
          );
        }
      }
    });
  }

  public getAllSitesWithLeadsAvailable() {
    const request = new SitesRequestModel();
    this.sitesService.getSitesWithLeads(request).then((response) => {
      this.siteWithLeadsResponse = response;
      /**
      for (let site in this.siteWithLeadsResponse.data) {
        let leads = JSON.parse(this.siteWithLeadsResponse.data[site].jsonInfo);
        this.siteWithLeads[leads.siteId]=leads.leadCount;
      }
       **/
    });
  }

  public goToBiling() {
    this.$router.push("/billing");
  }

  public goToMegaData() {
    this.$router.push("/megadata");
  }


  public goToHome() {
    this.$router.push("/dashboard");
  }

  private getNotificationCount() {
    this.notificationsService.getNotificationCount().then((response) => {
      this.notificationCount = response;
    });
  }

  private getNotifications() {
    this.notificationsService.getNotifications().then((response) => {
      this.notifications = response;
    });
  }

  public editProfile() {
    this.service.getCurrentUser().then((response) => {
      this.userRequest = response;
      this.userToggle = false;
      this.toggleEditProfileModel = true;
    });
  }

  public onCloseProfileModel(trigger: boolean) {
    this.toggleEditProfileModel = false;
  }

  public onCloseAppParams() {
    this.toggleAppParamsModel = false;
  }

  public updateSiteId(site: any) {
    this.sitesToggle = false;
    this.store.dispatch("updateSiteId", site.id);
    this.store.dispatch("updateSiteName", site.siteName);
  }

  logout() {
    this.store.dispatch("logout");
    this.$router.push("/login");
  }

  public updateHelpDeskStatus() {
    this.store.dispatch("triggerHelpDesk");
  }

  public onClosePassword() {
    this.allowChangePassword = false;
  }

  public clickOutSideSite() {
    this.sitesToggle = false;
  }

  public clickOutSideNotifications() {
    this.showNotifications = false;
  }

  public clickOutSideUser() {
    this.userToggle = false;
  }

  public clickOutSideHelpDesk() {
    this.store.dispatch("closeHelpDesk");
  }

  public updateSideBar() {
    this.store.dispatch("updateSideBar");
  }

  get page() {
    return this.$route.name;
  }

  get userInfo() {
    return this.store.getters.userInfo;
  }

  get helpDesk() {
    return (
        this.store.getters.getHelpDeskStatus
    );
  }

  get credits() {
    return this.store.getters.getCredits ? this.store.getters.getCredits : 0;
  }

  get sideBar() {
    return this.store.getters.getSideBarStatus;
  }

  get siteName() {
    return this.store.getters.getSiteName;
  }

  get siteId() {
    return this.store.getters.getSiteId;
  }

  get logo() {
    return Settings.AppLogo;
  }

  get leadName() {
    return Settings.LeadName;
  }

  get subscriptionMode() {
    return Settings.SubscriptionMode;
  }

  get releaseMode() {
    return Settings.ReleaseMode;
  }

  get announcement() {
    return Settings.Announcement;
  }

  get megadatainitcount() {
    return Settings.MegaDataInitCount;
  }

  get manualUpload() {
    return Settings.ManualUpload;
  }
}
